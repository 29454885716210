@font-face {
  font-family: "Open Sauce";
  src: url("../fonts/opensauce-medium.woff2") format("woff2"),
    url("../fonts/opensauce-medium.woff2") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sauce";
  src: url("../fonts/opensauce-regular.woff2") format("woff2"),
    url("../fonts/opensauce-regular.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
