.home__projects__project {
  color: $col-boulder;

  @media (any-pointer: fine) {
    &:hover {
      .label__inner {
        p {
          &:last-of-type {
            color: $col-gray;
          }
        }
      }
    }
  }

  .project__link {
    @include media("<=phone") {
    }
    text-align: right;
    padding-right: 5rem;
    padding-bottom: 3.1rem;

    @include media(">phone") {
      display: none;
    }

    .c-button .c-link__inner > span {
      margin-top: -4px;
      display: block;
    }

    .share-icon {
      position: relative;
      top: 0.3rem;
    }
  }

  &__label {
    @extend %description-12;
    line-height: 1.3;
    display: flex;
    justify-content: flex-end;
  }

  &__label {
    margin-top: 3.2rem;
    padding-right: 2.2rem;

    p {
      transition: color 0.3s;
    }

    @include media(">tablet") {
      padding-right: 17.2rem;
    }

    .label-1 {
      max-width: 84.3rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  &__link {
    pointer-events: none;

    @media (any-pointer: fine) {
      pointer-events: auto;
    }

    &.gradient {
      .title__main {
        background: $gradient;
        background-size: 200% 100%;
        animation: textGradientAnim 5s ease infinite alternate;
        overflow: hidden;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: background 0.3s;

        .slide-up {
          &::before {
            background: $col-orange;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      @keyframes textGradientAnim {
        0% {
          background-position: 0 50%;
        }

        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0 50%;
        }
      }
    }

    .title__main {
      position: relative;

      @include media("<=phone") {
        &.right {
          text-align: right;
        }
      }
      .slide-up {
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        overflow: hidden;
        transform: translateY(100%);
        transition: transform 275ms ease;

        @media (any-pointer: fine) {
          display: inline-block;
        }

        &::before {
          display: inline-block;
          content: attr(data-content);
          color: $col-orange;
          transform: translateY(-100%);
          transition: transform 275ms ease;

          @include media(">tablet") {
            white-space: nowrap;
          }
        }
      }
    }

    &:hover {
      .title__main {
        .slide-up {
          transform: translateY(0);

          &::before {
            transform: translateY(0);
          }
        }
      }
    }
  }

  &__title {
    @extend %title-160;
    line-height: 1;

    @include media(">tablet") {
      white-space: nowrap;
    }
    text-transform: uppercase;
    padding-top: 6.4rem;
    padding-bottom: 4.5rem;

    @include media("<=phone") {
      padding-top: 3.2rem;
      padding-bottom: 2.4rem;
      line-height: 0.9;
    }
    .inline-ovh {
      display: inline-flex;
      overflow: hidden;
    }
  }
}
