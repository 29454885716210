$col-black: #111111;
$col-white: #ffffff;
$col-orange: #cb450c;
$col-boulder: #777777;
$col-gray: #aaaaaa;
$font-bebas: "Bebas Neue", sans-serif;
$font-sauce: "Open Sauce", arial;
$gradient: linear-gradient(
  90deg,
  rgba(233, 227, 198, 0.5) 0%,
  rgba(233, 198, 217, 0.5) 100%
);
