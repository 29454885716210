%title-328 {
  font-size: 32.8rem;
  // letter-spacing: -0.05em;
  font-family: $font-bebas;
  word-break: break-word;

  @include media("<=phone") {
    font-size: 160px;
  }
}

%title-160 {
  font-size: 16rem;
  font-family: $font-bebas;
  letter-spacing: -0.025em;

  @include media("<=phone") {
    font-size: 80px;
    word-break: break-word;
  }
}
